<template>
  <!--有加减操作-->
  <!--简单商品情况-->
  <div :class="['detail-box', 'flexcenter', {bordernone: bordernone}]">
    <div v-if="isShowChose" @click="onCheckIt">
      <i v-if="checked" class="iconfont iconxuanzhong"></i>
      <i v-else class="iconfont iconweixuanzhong"></i>
    </div>
    
    <div :class="isMini?'left mini':'left'">
      <img class="full-img" :src="goods.goods_image">
	  <div class="sku-box">{{goods.sku_goods_name}}</div>
    </div>
    <div class="right">
      
      <div class="title-box">
        <div class="title ellipsis2">{{goods.goods_name}}</div>
      </div>
      
      <div class="piao-box flexcenter">
        <div class="total">￥{{goods.order_price}} <span class="lt"></span></div>
        <van-stepper
            v-model="number"
			min="0"
            @plus="addToCartLocal(goods.goods_id, goods.sku_id)"
            @minus="minusTocart(goods.goods_id, goods.sku_id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
	import mixin from 'utils/mixin'
export default {
  name: 'product1',
  mixins: [mixin],
  props: {
    msg: String,
    isShowChose: {
      type: Boolean,
      default: false,
    },
    isMini: {
      type: Boolean,
      default: false,
    },
    bordernone: {
      type: Boolean,
    },
    goods:{
      type:Object,
    },
    number:{
      type:Number
    },
    checkedSku:{
      type: Array,
      default: [],
    }
  },
  data() {
    return {
      checked:false,
      numberLocal: 0,
    }
  },
  watch: {
      checkedSku (newV, oldV) { // watch监听props里status的变化，然后执行操作
        console.log(newV)
		if(this.InArray(this.goods.sku_id,newV))
		{
			this.checked = true;
		}
		else
		{
			this.checked = false;
		}
      }
  },
  methods: {
    onChange(event) {
      console.log(event.detail);
    },
    //选中
    onCheckIt(){
      if(!this.checked){
        this.$emit("checked-goods",{sku_id:this.goods.sku_id,status:true});
      }else{
        this.$emit("checked-goods",{sku_id:this.goods.sku_id,false:true});
      }

      this.checked = !this.checked
    },
    //加入购物车按钮
    addToCartLocal(gid, skuid){
      let goods = this.goods;
      let group = goods.batch_group;
      let that = this;
      //如果非SKU类型商品，直接加入到购物车
      this.addToCart(gid,skuid,1,group,(res) => {
        if(res.err){
          that.$toast(res.msg);
        }else{
          // this.number = this.number + 1
          that.$emit("refesh-page",{});
        }
      });
    },
    //减购物车商品数量
    minusTocart(e){
      let gid = this.goods.goods_id;
      let skuid = this.goods.sku_id;
      let that = this;
      //判断是否最后一件
      let number = this.number;
      let checked = this.checked;
      let group = this.goods.batch_group;
      //console.log(e.target.dataset);
      if(number=='1')
      {
		this.$dialog.confirm({
			title:'提示',
			message:'从购物车删除该商品？'
		}).then(res => {
            //如果非SKU类型商品，直接加入到购物车
            this.addToCart(gid,skuid,-1,group,function(res){
              that.$emit("refesh-page",{});
            });
        }).catch(() => {
			// on cancel
		  });

      }
      else
      {
        //如果非SKU类型商品，直接加入到购物车
        this.addToCart(gid,skuid,-1,group,function(res){
          that.$emit("refesh-page",{});
        });
      }
    },
    updateNumberToCartLocal(cart_id){
      // return
      let that = this
      //如果非SKU类型商品，直接加入到购物车
      this.updateNumberToCart(cart_id,(res) => {
        if(res.data.err){
          this.$toast(res.data.msg);
          that.$emit("refesh-page",{});
        }else{
          that.$emit("refesh-page",{});
        }
      });
    },
    onBlur:function(e){
    }
  }
}
</script>

<style scoped>
  /* components/product1/product1.wxss */
  .full-img {
    display: block;
    width: 100%;
  }
  .flexcenter {
    display: flex;
    align-items: center;
  }
  
  .detail-box {
    display: flex;
    justify-content: space-between;
    margin: 24px;
    padding: 20px 24px;
    background-color: #FFFFFF;
    border-bottom: 1px solid #EEEEEE;
  }
  .bordernone {
    border: 0;
  }
  .detail-box .iconfont {
    margin-right: 10px;
  }
  .detail-box .left {
    margin-right: 20px;
    width: 190px;
  }
  .detail-box .mini {
      width: 90rpx;
  }
  .detail-box .right {
    flex: 1;
  }
  .detail-box .right .title-box {
    margin-bottom: 25px;
    font-size: 28px;
    font-weight: bold;
    color: #191919;
  }
  
  .detail-box .left .sku-box {
	  position: relative;
      display: block;
      padding:2px 10px;
      font-size:26px;
      color:#FCC601;
      border-radius: 5px;
      float: right;
      margin-top: -60px;
      margin-right: 20px;
      background-color:#191919;
  }
  
  .detail-box .mini .sku-box {
	  position: relative;
      display: block;
      padding:2px 10px;
      font-size:20px;
      color:#FCC601;
      border-radius: 5px;
      float: right;
      margin-top: -30px;
      margin-right: 5px;
      background-color:#191919;
  }
  
  .detail-box .right .piao-box {
    justify-content: space-between;
    font-size: 28px;
  }
  .detail-box .right .piao-box .total {
    font-size: 30px;
    font-weight: bold;
    color: #F84F41;
  }
  .detail-box .right .piao-box .lt {
    font-size: 24px;
    color: #C7C7C7;
    text-decoration: line-through;
  }
</style>
